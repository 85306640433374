
<template>
  <div>
    <el-form label-width="90px" size="mini" inline>
      <el-form-item label="模糊搜索：">
        <el-input style="width:180px;" v-model="search.search" placeholder="请输入标题、审批单号">
        </el-input>
      </el-form-item>
      <el-form-item label="导入类型：">
        <el-select style="width:120px;" v-model="search.batchType" placeholder="选择导入状态">
          <el-option v-for="item in typeList" :label="item.label" :value="item.value" :key="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="审批状态：">
        <el-select style="width:120px;" v-model="search.auditStatus" placeholder="选择审批状态">
          <el-option v-for="item in stateList" :label="item.label" :value="item.value" :key="item.value"></el-option>
        </el-select>
      </el-form-item>
      <OrganizationSelect v-model="search.submitOrg" @reload="getList"></OrganizationSelect>
      <el-form-item label="提交时间：">
        <el-date-picker style="width:370px" v-model="submitTime" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" icon="el-icon-search" @click="searchClick">查询</el-button>
        <!-- <el-button type="primary" icon="el-icon-folder-opened" @click="exportList">导出</el-button> -->
      </el-form-item>
    </el-form>
    <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto" height="calc(100vh - 260px)">
      <el-table-column align="center" label="序号" width="50" type="index"></el-table-column>
      <el-table-column prop="title" align="center" label="标题" width="120" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" label="导入类型" width="80">
        <template slot-scope="scope">
          {{scope.row.batchType == 5?'前三后四':scope.row.batchType==6?'直接发放':''}}
        </template>
      </el-table-column>
      <el-table-column prop="submitByName" align="center" label="提交人" width="130"></el-table-column>
      <el-table-column prop="submitOrgName" align="center" label="提交机构" width="220"></el-table-column>
      <el-table-column prop="auditCode" align="center" label="审批单号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="submitTime" align="center" label="提交时间" width="150"></el-table-column>
      <el-table-column prop="integralGrantAmount" align="center" label="发放金额" width="110"></el-table-column>
      <el-table-column prop="integralWithdrawAmount" align="center" label="撤回金额" width="110"></el-table-column>
      <el-table-column prop="integralNumber" align="center" label="客户数量（人）"></el-table-column>
      <el-table-column align="center" label="审批状态">
        <template slot-scope="scope">
          <el-tag class="el-tag-style" size="mini" disable-transitions :type="auditStatusTag[scope.row.auditStatus] && auditStatusTag[scope.row.auditStatus].type" v-text="auditStatusTag[scope.row.auditStatus] && auditStatusTag[scope.row.auditStatus].text">
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="approverName" align="center" label="审批人"></el-table-column>
      <el-table-column prop="approvalTime" align="center" label="审批时间" width="150"></el-table-column>
      <el-table-column label="操作" align="center" width="270">
        <template #default="scope">
          <el-button type="text" icon="el-icon-view" @click="editDetail(scope.row,1)" style="color:#333">查看</el-button>
          <a :href="scope.row.fileUrl" v-if="scope.row.fileUrl" style="margin:0 10px">
            <el-button type="text" icon="el-icon-download">下载原文件</el-button>
          </a>
          <el-button type="text" v-if="scope.row.auditStatus == 2" icon="el-icon-folder-opened" @click="exportOne(scope.row)">导出清单</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="display:flex;width:100%">
      <div style="width:200px">
        <p style="line-height:30px;">发放总金额 : {{totalCount.integralGrantAmount || 0}}</p>
        <p>撤回总金额 : {{totalCount.integralWithdrawAmount || 0}}</p>
      </div>
      <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="search.pageSize" :current-page="search.pageNum" :total="total" style="text-align:center;flex:1"></el-pagination>
    </div>
    <EditBatch v-if="isEditDialog" :isAddDialog.sync="isEditDialog" :editId.sync="editId" :editType.sync="editType"></EditBatch>
  </div>
</template>
<script>

import OrganizationSelect from '@/components/OrganizationSelect.vue'
import { queryBatchList, withdraw, voucherQueryList, delAuditBatch, queryBatchTotalAmount } from "@/api/codes";
import { organizationList } from "@/api/setting";
import { getCookies, auditStatusTag, exportCommon } from "@/utils/utils";
import EditBatch from '@/components/EditBatch.vue'

export default {
  name: "importQuery",
  components: { EditBatch, OrganizationSelect },
  data() {
    return {
      auditStatusTag: auditStatusTag,
      search: {
        submitOrg: '',
        search: "",
        pageNum: 1,
        pageSize: 10,
        auditStatus: "",
        batchType: '5,6'
      },
      typeList: [
        { label: '全部', value: '5,6' },
        { label: '直接发放', value: '6' },
        { label: '前三后四', value: '5' }
      ],
      stateList: [
        { label: '全部', value: '' },
        { label: '未提交', value: '0' },
        { label: '审批中', value: '1' },
        { label: '审批通过', value: '2' },
        { label: '退回', value: '3' },
        { label: '撤回', value: '4' },
      ],
      submitTime: "",
      grantTime: "",
      list: [],
      organizationList: [],
      total: 0, //分页总页数
      isEditDialog: false,
      totalCount: {}
    };
  },
  watch: {
    isEditDialog() {
      this.getList();
    }
  },
  methods: {
    recall(id) {
      this.$confirm('是否撤回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        withdraw(id).then((response) => {
          this.$message({
            title: '错误',
            type: response.code == 200 ? 'success' : 'error',
            message: response.msg || response.error_msg || response.message,
            duration: 1500
          });
          this.getList();
        })
      })
    },
    deleteAudit(id) {
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        delAuditBatch(id)
          .then((response) => {
            this.$message({
              title: '错误',
              type: response.code == 200 ? 'success' : 'error',
              message: response.msg || response.error_msg || response.message,
              duration: 1500
            });
            this.getList();
          });
      })
    },
    // type == 1 查看详情 type == 2 编辑 type==3 提交
    editDetail(item, type) {
      this.editType = type;
      this.editId = item;
      this.isEditDialog = true;
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.getList(0);
    },
    handleCurrentChange(val) {
      this.search.pageNum = val;
      this.getList(0);
    },
    getList(t = 1) {
      let param = Object.assign({}, this.search);
      param.submitStartTime = this.submitTime && this.submitTime.length ? this.submitTime[0] : "";
      param.submitEndTime = this.submitTime && this.submitTime.length ? this.submitTime[1] : "";
      param.approvalStartTime = this.grantTime && this.grantTime.length ? this.grantTime[0] : "";
      param.approvalEndTime = this.grantTime && this.grantTime.length ? this.grantTime[1] : "";
      if (t == 1) {
        queryBatchTotalAmount(param).then(res => {
          this.totalCount = res.data || {}
        })
      }
      queryBatchList(param).then(response => {
        if (response.code == 200) {
          response.data = response.data || {}
          this.total = response.data.total;
          this.list = response.data.records;
        } else {
          this.$message.error({
            title: "错误",
            message: response.msg,
            duration: 1500
          });
        }
      });
    },
    searchClick() {
      this.search.pageNum = 1;
      this.getList();
    },
    exportOne(row) {
      console.log(row)
      let param = {
        batchId: row.batchId,
        batchType: row.batchType
      }
      exportCommon("/wlynIntegral/exportExcel", param);
    },

    exportList() {
      let param = {};
      Object.assign(param, this.search)
      param.submitOrg = param.submitOrg || getCookies('organizationId')
      param.submitStartTime = this.submitTime && this.submitTime.length ? this.submitTime[0] : "";
      param.submitEndTime = this.submitTime && this.submitTime.length ? this.submitTime[1] : "";
      param.approvalStartTime = this.grantTime && this.grantTime.length ? this.grantTime[0] : "";
      param.approvalEndTime = this.grantTime && this.grantTime.length ? this.grantTime[1] : "";
      exportCommon("/auditBatch/exportBatchListExcel", param);
    }
  }
};
</script>